


























// Core
import { Component, Vue, Watch, PropSync } from 'vue-property-decorator'

@Component({
  name: 'BurgerMenu',

  components: {
    'v-logout': () => import('@/components/Logout.vue'),
  },
})
export default class BurgerMenuComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Watch('$route.path')
  public changeRouter() {
    this._visible = false
  }
}
